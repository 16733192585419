import { useStyles, Button, Flex } from '@archipro-design/aria';
import EnquiryModal from '~/modules/enquiry/component/enquiry-modal/EnquiryModal';
import * as S from './HeaderSection.style';
import { useMatchesHandleData } from '@modules/root';
import { useTracker } from '@archipro-website/tracker';
import { useIsomorphicLayoutEffect } from 'ahooks';
import type { EnquiryItemType } from '~/modules/tracking/util/trackEnquireReveal';
import {
    trackBookConsultationReveal,
    trackEnquireReveal,
} from '~/modules/tracking/util/trackEnquireReveal';
import { useSearchParams } from '@remix-run/react';
import BookingModal from '@modules/enquiry/component/booking-modal/BookingModal';
import { InboxCustom } from '@archipro-design/icons';
import type { BookConsultationProfessional } from '@modules/enquiry/component/enquiry-form/EnquiryForm';

interface BookProfessionalProps {
    isEnquiryOpen?: boolean;
    onEnquiryModalOpenChange?: (isOpen: boolean) => void;
    enquiryDrawer?: boolean;
    professional: BookConsultationProfessional;
    enquiryType: EnquiryItemType;
    extraTracking?: () => void;
    trackingRecordID?: number;
}

const BookProfessional = ({
    isEnquiryOpen,
    onEnquiryModalOpenChange,
    enquiryDrawer,
    professional,
    enquiryType,
    extraTracking,
    trackingRecordID,
}: BookProfessionalProps) => {
    const { branchOptions, ID, Title, TeamCalendarURLSegment, Link } =
        professional;
    const isBMPreview = useMatchesHandleData('isBMPreview', false);
    const [searchParams] = useSearchParams();
    const tracker = useTracker();
    const { css } = useStyles({ isBMPreview });

    useIsomorphicLayoutEffect(() => {
        if (!isBMPreview && searchParams.get('enquiry')) {
            onEnquiryModalOpenChange && onEnquiryModalOpenChange(true);
        }
    }, [searchParams, isBMPreview]);

    const handleEnquiryModalOpenChange = () => {
        onEnquiryModalOpenChange && onEnquiryModalOpenChange(!isEnquiryOpen);
    };

    //EnquiryModal sets localOpen when onOpenChange is not defined
    const openChange = onEnquiryModalOpenChange
        ? { onOpenChange: handleEnquiryModalOpenChange }
        : {};

    return (
        <Flex className={css(S.EnquiryAndSaveWrapper)}>
            {TeamCalendarURLSegment && (
                <BookingModal
                    teamBookingSegment={TeamCalendarURLSegment}
                    professionalUrl={Link}
                    trigger={
                        <Button
                            color="dark"
                            disabled={isBMPreview}
                            className={css(S.HeaderButton)}
                            size={16}
                            onClick={() =>
                                trackBookConsultationReveal(tracker, {
                                    professionalName: Title,
                                    itemID: trackingRecordID || ID,
                                    itemType: enquiryType,
                                    source: 'EnquiryModal',
                                })
                            }
                        >
                            Book a consultation
                        </Button>
                    }
                />
            )}
            <EnquiryModal
                open={isEnquiryOpen}
                {...openChange}
                professional={professional}
                branchOptions={branchOptions}
                trigger={
                    <Button
                        variant="outlined"
                        disabled={isBMPreview}
                        className={css(S.SquareIconButton)}
                        size={16}
                        onClick={() => {
                            trackEnquireReveal(tracker, {
                                professionalName: Title,
                                itemID: trackingRecordID || ID,
                                itemType: enquiryType,
                                source: 'EnquiryModal',
                            });
                            extraTracking && extraTracking();
                        }}
                        icon={<InboxCustom />}
                    />
                }
                drawer={!!enquiryDrawer}
            />
        </Flex>
    );
};

export default BookProfessional;
